<template>
    <div>
        <NavBar />
        <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;height: 100vh;">
            <h1 style="color: #F66;">百万鱼塘，需要请扫描下方二维码详细咨询</h1>
            <img src="../../assets/WechatCode.jpeg" style="width: 400px;height: 550px;margin-top: 30px;" />
        </div>
    </div>
</template>

<script>
import NavBar from 'components/navbar/NavBar'
import { myMemberInfo } from 'network/api'
export default {
  components: {
    NavBar
  },
  data () {
    return {
      isShow: false
    }
  },
  created () {
    // this.getMemberinfo()
  },
  methods: {
    getMemberinfo () {
      myMemberInfo().then((res) => {
        if (res.data.total_recharge_money >= 50) {
          this.isShow = true
        }
      })
    }
  }

}
</script>
